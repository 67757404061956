import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Browse from './pages/browse/Browse';
import Search from './pages/search/Search';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route exact path='/' Component={Browse} />
				<Route path='/search' Component={Search} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
